<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end"></div>
            <div class="row py-3">
              <div class="col-md-4 col-12 d-flex align-items-center">
                <div class="symbol symbol-60 symbol-lg-70 details-symbol">
                  <div
                    class="symbol-label symbol-label-profile"
                    :style="
                      adminProfilePicture
                        ? 'background-image: url(' +
                          adminProfilePicture +
                          '); background-color: #ffffff;'
                        : ''
                    "
                  >
                    <img
                      v-show="false"
                      :src="adminProfilePicture"
                      alt="image"
                      @error="imageError = true"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-12 row align-items-center">
                <div class="col-12">
                  <div class="font-weight-bolder font-size-h5 text-dark-75">
                    <b-skeleton v-if="!admin.id" type="text" height="1.5rem" />
                    <span>{{ admin.name }}</span>
                  </div>
                  <div class="text-muted">
                    <b-skeleton v-if="!admin.id" type="text" width="60%" />
                    <span>{{ admin.email }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: isExactActive }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <adminInformation
          v-if="$route.name === 'adminAccountUsersDetails'"
          @client-updated="loadAdmin"
        />
        <adminAccessData
          v-else-if="$route.name === 'adminAccountUsersDetailsAccessData'"
        />
      </div>
    </div>
  </div>
</template>

<style>
.disabledTab {
  display: none;
}
</style>

<script>
import Admins from "@/components/Admins/Account/Admin/admins";
import adminInformation from "@/components/Admins/Account/Admin/Details/Information";
import adminAccessData from "@/components/Admins/Account/Admin/Details/AccessData";
import { bus } from "@/main";

import Swal from "sweetalert2";

export default {
  name: "AdminsAccountAdminDetails",
  components: {
    adminInformation,
    adminAccessData
  },
  data() {
    return {
      imageError: false,
      admin: {},
      adminProfilePicture: null,
      tabIndex: 0,
      tabNavigation: [
        {
          route: "adminAccountUsersDetails",
          label: "users.baseData",
          icon: "fal fa-user"
        },
        {
          route: "adminAccountUsersDetailsAccessData",
          label: "users.accessData",
          icon: "fal fa-key"
        }
      ]
    };
  },
  mounted() {
    this.loadAdmin();
    bus.$on("adminUpdated", this.loadAdmin);
  },
  destroyed() {
    bus.$off("adminUpdated", this.loadAdmin);
  },
  methods: {
    loadAdmin() {
      var userId = this.$route.params.id;
      Admins.get(userId)
        .then(response => {
          this.admin = response.data.data;
          if (this.admin.profile_picture && !this.imageError) {
            this.adminProfilePicture = this.admin.profile_picture.link;
            return;
          }
          this.adminProfilePicture = "/media/users/blank.png";
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  }
};
</script>
